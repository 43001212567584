import React from 'react';
import Routes from './routes';

class App extends React.PureComponent {
  render() {
    return (
      <Routes />
    );
  }
}

export default App;