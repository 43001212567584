import {
    Routes,
    Route,
  } from "react-router-dom";
import Main from './Main';
import Nice from './Nice';

  const routes = () => {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/nice" element={<Nice />} />
        </Routes>
    );
}

export default routes;