import React, { useEffect } from 'react';
// import {encode as base64_encode} from 'base-64';
import axios from 'axios';
import qs from 'qs';

export default function Main() {
    useEffect(() => {
    // let formData = {
    //   clientID: 'afdd4f23-f2a3-429f-86cb-87b8a2a368bc',
    //   secret: '7a5f5e126a0851ecaf591daecd563d43',
    // };
    // const base64 = base64_encode(`${formData.clientID}:${formData.secret}`);
    const base64 = "YWZkZDRmMjMtZjJhMy00MjlmLTg2Y2ItODdiOGEyYTM2OGJjJTNBN2E1ZjVlMTI2YTA4NTFlY2FmNTkxZGFlY2Q1NjNkNDM=";
  
      var data = qs.stringify({
        'grant_type': 'client_credentials',
        'scope': 'default' 
      });
      var config = {
        method: 'post',
        url: '/niceApi/digital/niceid/oauth/oauth/token',
        headers: { 
          'Authorization': `Basic ${base64}`, 
          'Content-Type': 'application/x-www-form-urlencoded', 
        },
        data : data
      };

      const headers = config.headers;
      
      axios.post('/digital/niceid/oauth/oauth/token', data, { headers })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
      
      
    }, []);

    return (
      <React.Fragment>
        Nice API test Page: rollback to proxy v1
      </React.Fragment>
    );
  }
