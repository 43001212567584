import * as React from "react";
import { useState, useEffect } from "react";

import clsx from "clsx";

import imgLogoVisual from "./assets/images/img_logo_visual.png";
import imgLogoVisualM from "./assets/images/img_logo_visual_m.png";
import imgPhone01 from "./assets/images/phone/img_phone01.png";
import imgPhone01M from "./assets/images/phone/img_phone01_m.png";
import imgPhone02 from "./assets/images/phone/img_phone02.png";
import imgPhone02M from "./assets/images/phone/img_phone02_m.png";
import imgPhone03 from "./assets/images/phone/img_phone03.png";
import imgPhone03M from "./assets/images/phone/img_phone03_m.png";
import imgPhone04 from "./assets/images/phone/img_phone04.png";
import imgPhone04M from "./assets/images/phone/img_phone04_m.png";
import imgLogoB from "./assets/images/img_logo_ub_blue.svg";

export default function Main() {
  const [isActive, setIsActive] = useState([false, false]);

  const handleClick = (num) => {
    let prev = isActive;
    prev[num] = !isActive[num];
    setIsActive(() => [...prev]);
  };

  useEffect(() => {
    const moveScroll = () => {
      var winSCT = window.scrollY;
      var header = document.getElementById("header");

      if (winSCT > 0) {
        header.classList.add("header-fixed");
      } else {
        header.classList.remove("header-fixed");
      }
    };
    window.addEventListener("scroll", moveScroll);

    return () => {
      window.removeEventListener("scroll", moveScroll);
    };
  }, []);

  return (
    <div id="wrapper">
      <div id="container" className="container">
        <header id="header" className="header">
          <div className="top-contents">
            <div className="inner">
              <a href="#none" className="logo">
                <span className="blind">ublooming 로고 이미지</span>
              </a>
              <a
                href="mailto:help@ublooming.co.kr"
                title="문의하기"
                className="btn-more"
              >
                문의하기
              </a>
            </div>
          </div>
        </header>

        <div className="visual-info bg02">
          <div className="inner">
            <div className="top-contents">
              <a href="#none" className="logo">
                <span className="blind">ublooming 로고 이미지</span>
              </a>
              <a
                href="mailto:help@ublooming.co.kr"
                title="문의하기"
                className="btn-more"
              >
                문의하기
              </a>
            </div>
            <div className="service">
              <strong className="tit">소개팅 경험을 바꾸다.</strong>
              <p className="txt">
                연애를 꿈꾸는 당신을 위한
                <br />
                소개팅 서비스
                <span className="service-pic">
                  <img
                    src={imgLogoVisual}
                    className="pc-show"
                    alt="ublooming"
                  />
                  <img
                    src={imgLogoVisualM}
                    className="mo-show"
                    alt="ublooming"
                  />
                </span>
              </p>
              <div className="btn-wrap">
                <a
                  href="#none"
                  title="app store 바로가기"
                  className="btn ico-apple"
                >
                  <span className="blind">app store 바로가기</span>
                </a>
                <a
                  href="#none"
                  title="google play 바로가기"
                  className="btn ico-google"
                >
                  <span className="blind">google play 바로가기</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="solution-info">
          <div className="inner">
            <div className="category">
              <div className="category-box">
                <div className="info1">
                  <div className="tit-type01">커피 VS 맥주</div>
                  <div className="text01">
                    평일 커피 또는 주말 맥주 한잔을
                    <br />
                    선호 정보 기반 매칭을 통하여 만남 확률 상승! <br />
                    내가 보는 그/그녀는 언제 만남을 선호할까요?
                  </div>
                </div>
              </div>
              <div className="category-pic">
                <img src={imgPhone01} className="pc-show" alt="" />
                <img src={imgPhone01M} className="phone01 mo-show" alt="" />
              </div>
            </div>

            <div className="category img-lr">
              <div className="category-pic">
                <img src={imgPhone02} className="pc-show" alt="" />
                <img src={imgPhone02M} className="phone02 mo-show" alt="" />
              </div>
              <div className="category-box">
                <div className="info2">
                  <div className="tit-type01">직장, 학교 인증</div>
                  <div className="text01">
                    직장 또는 학교 인증을 통하여, 프로필 신뢰도를 높혔습니다.
                    검증된 이성과 신뢰를 기반으로 서로를 알아가요.
                  </div>
                </div>
              </div>
            </div>

            <div className="category">
              <div className="category-box">
                <div className="info3">
                  <div className="tit-type01">매칭 후 1:1 대화</div>
                  <div className="text01">
                    매칭 되지않은 이성과는 동시 대화가 불가능 합니다. 오직,
                    매칭된 이성과 로가 서로에게만 집중 할 수 있도록!
                  </div>
                </div>
              </div>
              <div className="category-pic">
                <img src={imgPhone03} className="pc-show" alt="" />
                <img src={imgPhone03M} className="phone03 mo-show" alt="" />
              </div>
            </div>

            <div className="category img-lr">
              <div className="category-pic">
                <img src={imgPhone04} className="pc-show" alt="" />
                <img src={imgPhone04M} className="phone04 mo-show" alt="" />
              </div>
              <div className="category-box">
                <div className="info4">
                  <div className="tit-type01 ico04">나를 표현하자!</div>
                  <div className="text01">
                    필터 가득한 사진 대신 본인을 나타낼 수 있는
                    <br className="pc-show" />
                    아바타를 사용 해보세요.
                    <br className="pc-show" />
                    실제로 만남이 이루어 지기까지 설레임을 조금 더 간직하고,
                    대화로 서로를 알아가 볼까요?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-info">
          <div className="inner">
            <div className="faq">
              <div className="tit">FAQ</div>
              <ol className="acco-step">
                <li>
                  <div className={clsx("acco-tit", { on: isActive[0] })}>
                    UBlooming은 어떤 서비스인가요?
                    <button
                      type="button"
                      className={clsx("btn-acco", { on: isActive[0] })}
                      onClick={() => handleClick(0)}
                    >
                      <span className="blind">내용보기</span>
                    </button>
                  </div>
                  <div className="acco-desc">
                    <ul>
                      <li>
                        <strong className="bold">1:1 채팅</strong> 서비스를
                        지향합니다. 서로에게 집중 할 수 있는 환경을 만들어
                        가고자 합니다.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className={clsx("acco-tit", { on: isActive[1] })}>
                    UBlooming은 어떤 장점이 있나요?
                    <button
                      type="button"
                      className={clsx("btn-acco", { on: isActive[1] })}
                      onClick={() => handleClick(1)}
                    >
                      <span className="blind">내용보기</span>
                    </button>
                  </div>
                  <div className="acco-desc">
                    <ul>
                      <li>
                        <strong className="bold">1:1 채팅</strong> 서비스를
                        지향합니다. 서로에게 집중 할 수 있는 환경을 만들어
                        가고자 합니다.
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <footer id="footer" className="footer">
          <div className="inner">
            <div className="footer-cont">
              <div className="footer-logo">
                <a href="#none">
                  <img src={imgLogoB} alt="로고 이미지" />
                </a>
              </div>
              <div className="foot-policy">
                <ul className="list-policy">
                  <li>
                    <a href="#none">
                      <span className="cr-black">개인정보 취급 방침</span>
                    </a>
                  </li>
                  <li>
                    <a href="#none">
                      <span className="cr-black">이용약관</span>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="list-corp">
                <li>
                  <ul className="list-policy">
                    <li>인하우스소프트</li>
                    <li>경기도 광명시 소하로 190, 광명G타워 A동 1020호</li>
                    <li>대표이사 : 이태린</li>
                  </ul>
                </li>
                <li>사업자등록번호 : 731-08-02170</li>
                <li>
                  문의 이메일 :
                  <a href="mailto:help@ublooming.co.kr">help@ublooming.co.kr</a>
                  (이메일 고객센터만 운영)
                </li>
              </ul>
              <p className="copyright">
                Inhousesoft @ 2024. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
